export default {
	data() {
		return {
			globalCountryId: null,
		};
	},

	methods: {
		setGlobalCountryFilter() {
			let globalCountryId = localStorage.getItem('global-country');
			if (globalCountryId && !this.quickFilter) {
				this.filters.CountryId = globalCountryId;
				this.globalCountryId = globalCountryId;
			}
		},
	},
};
