<template>
	<div class="field-autocomplete">
		<autocomplete ref="autocompleteRef" v-bind="autocompletAttrs"></autocomplete>
	</div>
</template>

<script>
import Autocomplete from 'vue2-autocomplete-js';
import http from '@/services/helpers/http';

/**
 * Wrapper for autocomplete component from 'vue2-autocomplete-js' library
 * Use as datatable column filter
 *
 * props are same as library autocomplet has, some of them have default values
 */
export default {
	components: {
		autocomplete: Autocomplete,
	},
	data() {
		return {
			/** Default settings for autocomplete component */
			defaultAutocompleteAttrs: {
				customHeaders: http.getHeaders(true),
				classes: { input: 'form-control' },
				debounce: 500,
				anchor: 'Name',
				label: '',
			},
		};
	},
	computed: {
		autocompletAttrs() {
			return {
				...this.defaultAutocompleteAttrs,
				...this.$attrs,
			};
		},
	},
	methods: {
		/** Clear autocomplete component, optionally can set value */
		clear(defaultValue = '') {
			this.$refs.autocompleteRef.setValue(defaultValue);
		},
	},
};
</script>

<style lang="scss">
@import '~vue2-autocomplete-js/dist/style/vue2-autocomplete.css';
/*
.field-autocomplete input {
	display: block;
	padding: 6px 12px;
	font-size: 12px;
	width: 100%;
	line-height: 1.42857143;
	color: #555;
	background-color: var(--white);
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
*/
/*
.autocomplete-wrapper {
	width: 100%;
}
*/
.autocomplete > ul {
	z-index: 100;
	opacity: 0.9;
}
</style>
